<template>
  <div class="container">
    <!-- Comes with a transaction  -->
    <!-- This will only support purchased items to begin with -->

    <div  v-if="transaction" class="text-center h2 mt-5">
      {{ transaction.source == "WITHDRAW" ? 'Withdraw' : "" }}{{ transaction.source == "DEPOSIT" ? 'Deposit' : "" }} Confirmed!
    </div>
    
    
    <div v-if="transaction" class="my-2">
      <!-- <div style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"> -->
        <div class="h4 text-center">Amount: ${{ (numeral(transaction.amount)._value / 100).toFixed(2)}}</div>
        <div class="h5 text-center">Method: {{transactionMethodNiceNames[transaction.method] || transaction.method}}</div>
      <!-- </div> -->
    </div>
    <div v-else class="my-5">
      <div class="loader mx-auto mt-2"></div>
    </div>

    <div class="text-center text-muted">
      Transaction confirmation number: <span role="textbox" class="input copyme" @click="copy">{{ transactionId }}</span>
    </div>
    
    <div v-if="flavour[transaction.method]" class="text-muted text-center mt-4">{{ flavour[transaction.method] }}</div>

    <div class="d-flex mt-4">
      <button class="btn btn-primary mx-auto" @click="print">Print / Save as PDF</button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
// Swal = Swal.mixin({ heightAuto: false, })

import * as mock from '@/mock.js';
import ScrollView from '../components/ScrollView.vue';

import api from "@/api.js";
import events from "@/events.js";
import numeral from 'numeral';
import parseItems from "../utils/parseItems";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      flavour: {
        // 'Stripe': "",
        // 'PayPal': "",
        'Coinbase': "Please allow 30 minutes for delivery. Some transactions may take up to a few hours. Check the activity page for order updates. ",
        // 'Venmo': "",
        'Dwolla': "Please allow 1-3 business day for delivery.",
      },
      transactionMethodNiceNames: {
        "Stripe": "Credit / Debit card",
      },
      transactionId: "",
      transaction: null,
      items: [],
      selectedItems: [],
      selectedItemId: null,
    }
  },
  async mounted() {
    try {
      Swal.close()
    } catch (e) {}
    // Pull the transaction.
    let transactionId = this.$route.params.transactionId;
    this.transactionId = transactionId;
    let userId = this.user.id;

    console.log(`Loading transaction ${userId} - ${transactionId}`);
    let transactions   = (await api.getTransactions({
      id: transactionId,
    })).data;
    console.log("TX loaded")
    this.transaction   = transactions[0];
    this.selectedItems = this.items;
  },
  components: {
    ScrollView

  },
  computed: {
    selectedItem() {
      return this.items.find(x => x.id == this.selectedItemId)
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    sort() {
      return this.sortColumn + '-' + this.sortDirection;
    },
    sortedByGames() {
      let itemsByGameId = {};
      for (let item of this.items) {
        let appId = item.appId || item.steamItem.appId || item.steamItem.appid;
        appId = appId+'';
        itemsByGameId[appId] = itemsByGameId[appId] || [];
        itemsByGameId[appId].push(item);
      }

      let games = [ 
        { id: 730, name: 'CS:GO', path: "/csgo", items: itemsByGameId['730'] || [] }, 
        { id: 440, name: 'TF2', path: "/tf2", items: itemsByGameId['440'] || [] },
        { id: 570, name: "Dota 2", path: "/dota2", items: itemsByGameId['570'] || [] },
        { id: 252490, name: "Rust", path: "/rust", items: itemsByGameId['252490'] || [] },
        // { id: 433850, name: "Z1:BR", path: "/z1br", items: itemsByGameId['433850'] || [] }
      ];

      return games.filter(game => game.items.length > 0)
    },
  },
  methods: {
    numeral,
    print() {
      window.print()
    },
    openSellModal(itemId) {
      this.selectedItemId = itemId;
      // Open the modal
      $('#sellmodal').modal('show');
    },
    copy() {
      try {
        document.execCommand('copy')
      } catch (e) {
        console.log("Unable to copy.")
      }
    },
    toggleSort(column) {
      if (column == this.sortColumn) {
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
          return;
        }
        if (this.sortDirection == 'desc') {
          this.sortDirection = 'asc'
          return;
        }
      } else {
        this.sortDirection = 'desc';
        this.sortColumn = column;
        return;
      }
    },
    select(item) {
      let hasItem = this.selectedItems.findIndex(x=>x.id == item.id);

      if (hasItem > -1) {
        this.selectedItems.splice(hasItem, 1);
      } else {
        this.selectedItems.push(item);
      }
    },
    async withdrawSelected() {

      if (this.$store.getters["auth/user"].state == "HARD_BANNED") {
        return Swal.fire('Account Disabled', "Please contact support", 'danger');
      }
        // this.$store.dispatch('stepByStep/clear');

      this.$store.dispatch('stepByStep/startWithdrawal');

      if(this.selectedItems.length == 0) {
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You have not selected any items!`,
        });
      } else if (!this.user.tradeOfferUrl) {
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You have not set a tradeofferurl in your settings!`,
        });
      } else {
        console.log("Got into else");
        try {
          let response = await api.withdrawItems(this.selectedItems);

          this.$store.commit('stepByStep/updateTradeoffers', response.data.tradeoffers);
          this.$store.commit('stepByStep/completeStep');

          setTimeout(() => {
            this.redirect();
          }, 1000)
        } catch (e) {
          let error = `We failed to process some of your selected items.`
          if (e.response && e.response.data && e.response.data.errors && e.response.data.errors[0]) {
            error = e.response.data.errors[0];
          }
          console.log("Sad times");
          console.error(e);
          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: "withdrawalOrderRetry",
            message: error,
          });
        }
      }
    },
    async relistSelected() {
      try {
        for (let item of this.selectedItems) {
          await this.$store.dispatch('items/updateListing', {
            partitionKey: item.depositerId,
            resourceId: item.id,
            changes: [{          
              key: "state",
              value: "LISTED",
              oldValue: "UN-LISTED"
            }]
          });
        }

        Swal.fire({
          title: `Relisted`,
          text: `We've relisted ${this.selectedItems.length} items`,
          icon: "success",
          heightAuto: false,
        });
        this.redirect();
      } catch (e) {
        console.error(e)
        Swal.fire({
          title: `Error`,
          text: `There was an error while re-listing one or more item`,
          icon: "error",
          heightAuto: false,
        });
      }
    },

    redirect() {
      let game = this.sortedByGames.find(x => x.items.length > 0);
      this.$router.push(game.path)
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/scss/item.scss';
  // Loader
  .loader {
    // position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;
    border: 8px solid #4e4e4e;
    border-radius: 50%;
    border-top: 8px solid $primary;
    // width: 120px;
    // height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }

  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }

  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }

  .copyme {
    border-radius: 5px;
    background-color: #cdcdcd;

    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    
    width: auto;
    display: inline-block;
    color: black;
    -webkit-user-select: all;  /* Chrome 49+ */
    -moz-user-select: all;     /* Firefox 43+ */
    -ms-user-select: all;      /* No support yet */
    user-select: all;          /* Likely future */  

    cursor: pointer;
  }

  .item-image {
    padding-bottom: 10px;
  }

  .item-name {
    min-width: 150px;
  }
  .item-title {
    max-width: 100%;
  }

/* Tooltip container */
.item-tooltip-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  cursor: pointer;
}

/* Tooltip text */
.item-tooltip-container .item-tooltip {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding: 5px 0; */
  /* border-radius: 6px; */
 
  /* Position the tooltip text - see examples below! */

  top: 0%;
  left: 0px; /* To the left of the tooltip */
  position: absolute;
  z-index: 1;
}

.item-tooltip-container:hover{
  border-bottom: 1px solid white; 

}
/* Show the tooltip text when you mouse over the tooltip container */
.item-tooltip-container:hover .item-tooltip {
  visibility: visible;
}
</style>